.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.input-group label.form-label {
  width: 40%;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.my-fieldset-legend{
  background-color: silver;
  color: white;
  padding: 5px 10px;
  margin: 20px;
  width: fit-content;
  font-size: large;
}

.my-fieldset{
  border: 2px solid lightsteelblue;
  background-color: #fefefa;
  padding-left: 5px;
}
.my-navbar-bg {
  background-color: #414141;
  color: dimgrey;
  opacity: 0.85;
}

.gradient{
  background-image: linear-gradient( 90deg, #000000, #414141 12%, #cacaca);
}
.gradient2{
  -webkit-mask-image: -webkit-gradient(linear, left top,
  left bottom, from(rgba(0,0,0,1)), to(rgba(0,0,0,0)));
}
a.nav-link:hover{
  color: #d5cda2 !important;
}

.paging{
  background-color: silver;
  padding-left: 10px;
  white-space: nowrap;
  width: max-content;
}
.bg-mint{
  background-color: #d0f0e7;
}